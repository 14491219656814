import React, { useCallback, useEffect, useState } from "react";
import iconoPlantillaServices from "../../css/img/IconoPlantillaServicio.svg";
import { Edit, PlusSquare } from "lucide-react";
import DgForm from "../../components/DgForm";
import DgButton from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import {
  conceptSchema,
  serviceSchema,
  subClientSchema,
} from "../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {
  getGeolocationData,
  handleNestedChange,
  handleNestedItems,
  parseCat,
} from "../../helpers/utils";
import { useSelector } from "react-redux";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../components/ButtonIcon";
import Swal from "sweetalert2";
import { Request } from "../../helpers/api";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  addressInputs,
  conceptsSchema,
  editTemplateServiceBasicDataSchema,
  subclientsSchema,
} from "../../helpers/formUtils";
import useGetZipCode from "../../helpers/useGetZipCode";
import { clientTypes } from "../../helpers/enums";
import DgModal from "../../components/DgModal";
import DgLoader from "../../components/DgLoader";

const ModalPlantillaServices = ({
  isOpen,
  toggle,
  onConfirm = () => { },
  singleData = { ...serviceSchema },
  updateServicesList,
}) => {
  const [fiscalPeriodList, setFiscalPeriodList] = useState([
    { idFiscalPeriod: "", name: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...serviceSchema,
  });
  const {
    serviceConcepts: catConcepts,
    catRegions: regions,
    CatRegime,
    CatCfdi,
    CatPaymentWay,
  } = useSelector((s) => s.catalogues);

  const [handleZipChange, suburbs, inputType, setInputType] = useGetZipCode(
    data.postalCodeService,
    data,
    setAllData,
    "Service"
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await Request(
      "/service/servicetemplate/" + singleData.idServiceTemplate,
      {
        ...data,
        distributeAmounts: data.detailService.length > 1,
      },
      "PUT"
    );
    setLoading(false);

    if (res.ok) {
      Swal.fire("Éxito", "Plantilla modificada con éxito.", "success").then(
        () => {
          toggle();
          console.log(res.client.serviceTemplates)
          if(updateServicesList){
            updateServicesList(res.client?.serviceTemplates || []);
          }
          
        }
      );
    }
  };
  const fetchFiscalPeriods = useCallback(async () => {
    const res = await Request(`/user/fiscalPeriods?limit=6&all=false`);
    if (res.ok) {
      setFiscalPeriodList(res.data);
    }
  }, []);
  const getFields = () => {
    const getConceptFields = () => {
      let fields = [];
      data.serviceConcepts.forEach((nestedData, nestedIndex) => {
        const onNestedChange = (event) => {
          handleNestedChange(event, nestedIndex, "serviceConcepts", data, {
            onDataChange,
            handleZipChange,
          });
        };

        fields = fields.concat(
          conceptsSchema(
            data,
            nestedData,
            nestedIndex,
            { onNestedChange, onDataChange },
            { catConcepts }
          )
        );
      });
      return fields;
    };
    const getSubClientsFields = () => {
      let fields = [];
      data.detailService.forEach((nestedData, nestedIndex) => {
        const onNestedChange = (event) => {
          handleNestedChange(event, nestedIndex, "detailService", data, {
            onDataChange,
            handleZipChange,
          });
        };
        fields = fields.concat([
          ...subclientsSchema(
            data,
            nestedData,
            nestedIndex,
            { onNestedChange, onDataChange },
            { CatRegime, CatCfdi, CatPaymentWay, suburbs }
          ),
        ]);
      });
      return fields;
    };
    const serviceForm = [
      {
        groupClassNameGrid: "grid-cols-4",
        fields: editTemplateServiceBasicDataSchema(data, {
          onDataChange: onDataChange,
        }),
      },
      {
        title: "CONCEPTOS",
        withAdd: true,
        addElement: (
          <div
            onClick={() => {
              handleNestedItems(
                data,
                conceptSchema,
                { onDataChange },
                "serviceConcepts"
              );
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        ),
        groupClassNameGrid: "grid-cols-3 conceptsList",
        fields: getConceptFields(),
      },
      {
        title: "DOMICILIO",
        fields: [
          ...addressInputs(data, suburbs[0], "Service"),
          {
            label: "Región",
            placeholder: "Región",
            type: "select",
            name: "idCatRegion",
            required: true,
            value: data.idCatRegion,
            options: parseCat(regions, "name", "idCatRegion"),
            iconName: "MapPin",
            containerClassName: "mt-0",
          },
        ],
      },
    ];
    const subclientsForm = {
      title: "SUBCLIENTES",
      withAdd: true,
      formLength: 19,
      separator: true,
      addElement: (
        <div
          onClick={() => {
            handleNestedItems(
              data,
              subClientSchema,
              { onDataChange },
              "detailService"
            );
          }}
        >
          <PlusSquare
            fill="#2A2F99"
            color="white"
            size={35}
            style={{ display: "inline" }}
          />
          Agregar otro subcliente
        </div>
      ),
      fields: getSubClientsFields(),
    };

    if (data.distributeAmounts) {

      let updatedForm = [...serviceForm, subclientsForm];

      // if (document.getElementsByName("nameDetailService").length < 1) {
      //   updatedForm = [...serviceForm, subclientsForm, subclientsForm];
      // }

      return updatedForm;
    } else {
      return [
        ...serviceForm,
        {
          title: "SUBCLIENTES",
          withAdd: true,
          groupClassNameGrid: "grid-cols-3",
          addElement: (
            <div
              onClick={() => {
                setAllData({ ...data, distributeAmounts: true });
              }}
            >
              <PlusSquare
                fill="#2A2F99"
                color="white"
                size={35}
                style={{ display: "inline" }}
              />
              Agregar otro subcliente
            </div>
          ),
          fields: [],
        },
      ];
    }
  };

  const fetchData = useCallback(
    async (idServiceTemplate) => {
      const res = await Request(
        "/service/servicetemplate/" + idServiceTemplate
      );

      if (res.ok && res.data) {
        setAllData(res.data);
      }
    },
    [singleData]
  );

  useEffect(() => {
    handleZipChange();
  }, [data?.postalCodeService]);

  useEffect(() => {
    if (singleData.idServiceTemplate && isOpen) {
      fetchData(singleData.idServiceTemplate);
    }
  }, [singleData]);

  useEffect(() => {
    fetchFiscalPeriods();
  }, []);

  return (
    <DgModal
      title="PLANTILLA DE SERVICIO"
      titleStyle={{ paddingTop: "2rem" }}
      style={{ width: "90%", height: "95%", overflow: "auto" }}
      open={isOpen}
      toggle={toggle}
    >
      <ModalBody style={{ padding: "1rem 3rem 3rem 3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <img src={iconoPlantillaServices} style={{ width: "5rem" }} />
          <div
            style={{
              marginLeft: "1rem",
              fontWeight: 600,
              color: "#2A2F99",
            }}
          >
            COBERTURA DE EVENTO
          </div> */}
        </div>
        <DgForm
          zipError={data.error}
          data={data}
          classNameGrid="grid grid-cols-4 gap-4"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <ModalFooter>
            <div className="grid grid-cols-3 gap-5">
              <div />
              {
                singleData.isActive &&
                (
                  <DgButton type="submit" color="primary" onClick={onConfirm}>
                    Actualizar
                  </DgButton>                  
                )
              }

              <DgButton onClick={toggle}>Cerrar</DgButton>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
      <DgLoader open={loading} />
    </DgModal>
  );
};

export default ModalPlantillaServices;
