import React, { useCallback, useEffect, useState } from "react";
import iconoPlantillaServices from "../../css/img/IconoPlantillaServicio.svg";
import { Edit, PlusSquare } from "lucide-react";
import DgForm from "../../components/DgForm";
import DgButton from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import {
  cancelServiceSchema,
  conceptSchema,
  subClientSchema,
} from "../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {
  getGeolocationData,
  handleNestedChange,
  handleNestedItems,
  parseCat,
} from "../../helpers/utils";
import { useSelector } from "react-redux";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../components/ButtonIcon";
import Swal from "sweetalert2";
import { Request } from "../../helpers/api";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  addressInputs,
  conceptsSchema,
  editTemplateServiceBasicDataSchema,
  subclientsSchema,
} from "../../helpers/formUtils";
import useGetZipCode from "../../helpers/useGetZipCode";
import { clientTypes } from "../../helpers/enums";
import DgModal from "../../components/DgModal";
import DgLoader from "../../components/DgLoader";

const ModalCancelarPlantillaServicesFromClientTable = ({
  isOpen,
  toggle,
  onConfirm = () => { },
  singleData = { ...cancelServiceSchema },
  refresh,
}) => {

  const [loading, setLoading] = useState(false);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...cancelServiceSchema,
  });
  const {
    serviceConcepts: catConcepts,
    catRegions: regions,
    CatRegime,
    CatCfdi,
    CatPaymentWay,
  } = useSelector((s) => s.catalogues);

  const [handleZipChange, suburbs, inputType, setInputType] = useGetZipCode(
    data.postalCodeService,
    data,
    setAllData,
    "Service"
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    //setLoading(true);
    console.log({data})
    const res = await Request(
      "/service/inactivetemplateService/" + singleData.idServiceTemplate,
      {
        ...data,
      },
      "PATCH"
    );
    setLoading(false);

    if (res.ok) {
      Swal.fire("Éxito", "El servicio se canceló con éxito.", "success").then(
        () => {
          toggle();
          //TODO actualizar lista de la tabla
          //console.log(res.client.serviceTemplates)
          refresh();
        }
      );
    }
  };


  useEffect(() => {
    if (singleData.idServiceTemplate && isOpen) {
      //fetchData(singleData.idServiceTemplate);
      setAllData({
        idServiceTemplate:singleData.idServiceTemplate,
        dateInactiveServiceTemplate:"",
        commentInactiveServiceTemplate:""
      });
    }
  }, [singleData]);



  return (
    <DgModal
      title="BAJA DE SERVICIO"
      titleStyle={{ paddingTop: "2rem" }}
      style={{ width: "55%", height: "70%", overflow: "auto" }}
      open={isOpen}
      toggle={toggle}
    >
      <ModalBody style={{ padding: "1rem 3rem 3rem 3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >

        </div>
        <DgForm
          zipError={data.error}
          data={data}
          classNameGrid="grid-cols-1"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={[
            {
              fields: [
                {
                  label: "Fecha a partir de la cual aplica la baja",
                  placeholder: "25/07/1978",
                  type: "date",
                  name: "dateInactiveServiceTemplate",
                  required: true,
                  iconName: "Calendar",
                },
                {
                  type: "textarea",
                  label: "Motivo de la baja",
                  placeholder: "...",
                  name: "commentInactiveServiceTemplate",
                  // containerStyle: { width: "100%", margin: 0 },
                  value: "",
                  // onInputChange: onDataChange,
                  iconName: "StickyNote",
                },
              ],
            },
          ]}

        >
 
          <ModalFooter>
            <div className="grid grid-cols-3 gap-5">
              <div />

                  <DgButton type="submit" color="primary" onClick={onConfirm}>
                    Dar de baja
                  </DgButton>                  


              <DgButton onClick={toggle}>Cerrar</DgButton>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
      <DgLoader open={loading} />
    </DgModal>
  );
};

export default ModalCancelarPlantillaServicesFromClientTable;
