import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import Button from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DgInputMultipleFiles from "../../../components/DgInputMultipleFiles";
import FileUploadedList from "../../../components/FileUploadedList";
import DgLoader from "../../../components/DgLoader";
import { cfdiTypes } from "../../../helpers/enums";
import useGetEmailParams from "../../../hooks/useGetEmailParams";
import { sendEmailInputs } from "../../../helpers/schemas";
import ContactsConfirmationModal from "../../../components/ContactsConfirmationModal";

const ClientSendEmail = ({
  titleStyle,
  toggle,
  endPoint,
  idService,
  internalFolio,
  cfdiType,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailData, setEmailData, handleChange, handleSubmit] =
    useGetEmailParams(
      `/client/infoSendEmail/cfdi?cfdiType=${cfdiType}&idDetailService=${idService}&emailType=CDFI&internalFolio=${internalFolio}`,
      {
        clientContacts: [],
        subject: "",
        files: [],
        cfdiType: cfdiTypes[cfdiType],
        idDetailService: idService,
        addCounterEmail: false,
        addAdministratorEmail: false,
        emailAccountConfigs: [],
        cc: [],
        clientEmailTemplateBill: "",
        editTemplate: false,
        body: "",
        folioCfdi: "",
        idEmailAccountConfig: "",
      }
    );

  return (
    <div className="w-full p-10">
      <div
        className="text-[1.2rem] font-semibold text-sesBlue uppercase mb-[1rem]"
        style={titleStyle}
      >
        NUEVO EMAIL
      </div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          setConfirmationModalOpen(true);
        }}
      >
        <div className="flex flex-wrap justify-start">
          {sendEmailInputs(
            emailData,
            { onChange: handleChange },
          ).map((inputData) => (
            <DgInput {...inputData} />
          ))}
        </div>
        <Editor
          editorState={emailData.body}
          toolbarClassName="toolbarClassName"
          wrapperStyle={{
            boxShadow: "0px 2px 3px rgb(0 0 0 / 20%)",
            marginTop: "10px",
          }}
          editorStyle={{
            padding: "10px 15px",
            height: "300px",
            background: "#f9f9f9",
            border: "1px solid #d2d2d2",
          }}
          onEditorStateChange={(text) =>
            handleChange({
              target: { name: "body", value: text },
            })
          }
        />
        <FileUploadedList files={emailData.files} stateSetter={setEmailData} />
        <FileUploadedList
          files={[
            { name: "CFDI XML", required: true },
            { name: "CFDI PDF", required: true },
          ]}
        />
        <div className="flex w-fit gap-3 self-center">
          <Button className={"w-fit"} innerClassName="w-[300px]" type="submit">
            Enviar
          </Button>
          <Button
            className={"w-fit"}
            innerClassName="w-[300px]"
            onClick={toggle}
          >
            Cerrar
          </Button>
          <DgInputMultipleFiles
            name="files"
            onChange={handleChange}
            files={emailData.files}
          />
        </div>
        <ContactsConfirmationModal
          isLoading={isLoading}
          withSend={{
            title: "Confirmar",
            action: () => handleSubmit(toggle, setConfirmationModalOpen, setIsLoading, endPoint),
          }}
          open={confirmationModalOpen}
          toggle={() => setConfirmationModalOpen((prevState) => !prevState)}
          lists={[
            { label: "Destinatarios", contacts: emailData.clientContacts },
            { label: "Con copia", contacts: emailData.cc },
          ]}
        />
      </form>
    </div>
  );
};

ClientSendEmail.propTypes = {};

export default ClientSendEmail;
