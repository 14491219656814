import React from "react";
import PropTypes from "prop-types";
import InputIcon from "./InputIcon";
import "./DgInput.css";
import "./DgCheckbox.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DgInputFile from "./DgInputFile";
import FiltrableDataList from "./FiltrableDataList";
import ContactsInput from "../components/ContactsInput";

const DgInput = ({
  containerClassName = "",
  className = "",
  label = "",
  placeholder = label,
  type,
  iconName,
  required = false,
  errorMessage,
  name,
  disabled,
  value,
  onChange,
  onInputChange,
  options = [],
  containerStyle,
  multiple,
  error,
  style,
  handleBlur,
  onFocus,
  step,
  onDataChange,
}) => {
  const handleChange = (e) => (onInputChange || onChange)(e);

  const handleRadioChange = (e) => {
    const { target } = e;
    const value = ["true", "false"].includes(target.value)
      ? target.value === "true"
      : target.value;
    handleChange({ target: { name: target.name, value } });
  };

  const renderElement = () => {
    switch (type) {
      case "select":
        return (
          <select
            className={`cssInput text-sesGray placeholder-textGray placeholder-opacity-30 py-3 pr-8 pl-14 my-2 w-full ${className} `}
            onChange={handleChange}
            placeholder={placeholder}
            name={name}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            style={{ ...style, border: error ? "1px solid red" : null }}
          >
            {options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
        );

      case "radio":
        return (
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={name}
            value={value}
            onChange={handleRadioChange}
          >
            {options.map((o) => (
              <FormControlLabel
                key={o.value}
                value={o.value}
                control={<Radio />}
                label={o.label}
              />
            ))}
          </RadioGroup>
        );

      case "checkbox":
        return (
          <div className={`dgCheckbox`}>
            <input
              id={name}
              name={name}
              checked={value}
              value={value}
              onBlur={handleBlur}
              onChange={handleChange}
              type="checkbox"
            />
            <label htmlFor={name}>{placeholder}</label>
          </div>
        );

      case "file":
        return (
          <DgInputFile
            onChange={handleChange}
            required={required}
            name={name}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            multiple={multiple}
          />
        );

      case "dataList":
        return (
          <FiltrableDataList
            containerStyle={{ paddingTop: "0.5rem" }}
            style={{ border: error ? "1px solid red" : null }}
            onInputChange={handleChange}
            options={options}
            required={required}
            onBlur={handleBlur}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
          />
        );

      case "contactList":
        return (
          <ContactsInput
            label={label}
            required={required}
            iconName={iconName}
            errorMessage={errorMessage}
            name={name}
            onBlur={handleBlur}
            onChange={onChange}
            value={value}
          />
        );

      default:
        const Element = type === "textarea" ? type : "input";
        return (
          <Element
            className={`cssInput text-sesGray placeholder-textGray placeholder-opacity-30 py-3 pr-8 pl-14 my-2 w-full hola ${className}`}
            onChange={handleChange}
            containerClassName={containerClassName}
            placeholder={placeholder}
            type={type}
            required={required}
            onBlur={handleBlur}
            name={name}
            value={value}
            onWheel={(e) => e.target.blur()}
            onFocus={onFocus}
            style={{ ...style, border: error ? "1px solid red" : null }}
            disabled={disabled}
            step={step}
          />
        );
    }
  };

  return (
    <div
      style={containerStyle}
      className={`w-full mt-6 dg-input ${containerClassName}`}
    >
      {label && (
        <label className="text-sesBlue text-left text-base labelForm p-2 whitespace-nowrap">
          {required && "*"} {label}
        </label>
      )}
      {iconName && <InputIcon name={iconName} color="#565656" size={42} />}
      {renderElement()}
      {error && <span style={{ color: "red" }}>{errorMessage}</span>}
    </div>
  );
};

DgInput.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    "password",
    "text",
    "email",
    "number",
    "date",
    "tel",
    "select",
    "textarea",
    "radio",
    "checkbox",
    "file",
  ]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onDataChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  options: PropTypes.array,
  iconName: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  containerStyle: PropTypes.object,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  style: PropTypes.object,
};

export default DgInput;
