import React, { useCallback, useEffect } from "react";
import iconoPlantillaServices from "../../../css/img/IconoPlantillaServicio.svg";
import { PlusSquare } from "lucide-react";
import { useForm } from "../../../helpers/useForm";
import {
  conceptSchema,
  serviceSchema,
  subClientSchema,
} from "../../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { handleNestedChange, handleNestedItems } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { Request } from "../../../helpers/api";
import DgForm from "../../../components/DgForm";
import DgButton from "../../../components/Button";
import Swal from "sweetalert2";
import DgInput from "../../../components/DgInput";
import {
  editServiceBasicDataSchema,
  editServiceConceptsSchema,
  subclientsSchema,
} from "../../../helpers/formUtils";
import useGetZipCode from "../../../helpers/useGetZipCode";

const ModalServiceDetails = ({
  isOpen,
  toggle,
  onConfirm = () => { },
  data: serviceData,
  fetchData: fetchInvoicingTableData,
}) => {
  const [data, onDataChange, clearData, setAllData] = useForm(serviceSchema);
  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);
  const [handleZipChange, suburbs, inputType, setInputType] = useGetZipCode(
    data.postalCodeService,
    data,
    setAllData,
    "Service"
  );
  const { CatRegime, CatCfdi, CatPaymentWay } = useSelector(
    (state) => state.catalogues
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await Request(
      "/service/" + serviceData.idService,
      {
        ...data,
        distributeAmounts: data.detailService.length > 1,
      },
      "PUT"
    );

    if (res.ok) {

      // if (data.editTemplate) {
      //   const idService = res.data?.idService;
      //   await Request(`/service/${idService}/servicetemplate`, {}, "PUT");
      // }

      if (data.editFuturePeriodsServices) {
        const idService = res.data?.idService;
        await Request(`/service/${idService}/editfutureperiodsservices`, {}, "PUT");
      }



      Swal.fire("Éxito", "Servicio modificado con éxito.", "success").then(
        () => {
          toggle();
          fetchInvoicingTableData();
        }
      );
    }
  };
  const getFields = () => {
    const getConceptFields = () => {
      let fields = [];
      data.serviceConcepts.forEach((nestedData, nestedIndex) => {
        const onNestedChange = (event) => {
          handleNestedChange(event, nestedIndex, "serviceConcepts", data, {
            onDataChange,
          });
        };

        fields = fields.concat(
          editServiceConceptsSchema(
            data,
            nestedData,
            nestedIndex,
            { onNestedChange, onDataChange },
            { catConcepts }
          )
        );
      });
      return fields;
    };

    const getSubClientsFields = () => {
      let fields = [];
      data.detailService.forEach((nestedData, nestedIndex) => {
        const onNestedChange = (event) => {
          handleNestedChange(event, nestedIndex, "detailService", data, {
            onDataChange,
            handleZipChange,
          });
        };
        fields = fields.concat(
          subclientsSchema(
            data,
            nestedData,
            nestedIndex,
            { onNestedChange, onDataChange },
            { CatRegime, CatCfdi, CatPaymentWay, suburbs },
            { disabled: [2, 3].includes(nestedData.idCatStatusInvoiceService) },
            true
          )
        );
      });
      return fields;
    };

    const serviceForm = [
      {
        title: "CONCEPTOS",
        groupClassNameGrid: "grid-cols-6",
        withAdd: true,
        addElement: (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleNestedItems(
                data,
                conceptSchema,
                { onDataChange },
                "serviceConcepts"
              );
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        ),
        fields: getConceptFields(),
      },
    ];
    if (data.distributeAmounts) {
      return [
        ...serviceForm,
        {
          title: "SUBCLIENTES hola",
          groupClassNameGrid: "grid-cols-4",
          withAdd: true,
          addElement: (
            <div
              onClick={() => {
                handleNestedItems(
                  data,
                  subClientSchema,
                  { onDataChange },
                  "detailService"
                );
              }}
            >
              <PlusSquare
                fill="#2A2F99"
                color="white"
                size={35}
                style={{ display: "inline" }}
              />
              Agregar otro subcliente
            </div>
          ),
          fields: getSubClientsFields(),
        },
      ];
    } else
      return [
        ...serviceForm,
        {
          title: "SUBCLIENTES",
          withAdd: true,
          addElement: (
            <div
              onClick={() => {
                handleNestedItems(
                  data,
                  subClientSchema,
                  { onDataChange },
                  "detailService"
                );
                setAllData({ ...data, distributeAmounts: true });
              }}
            >
              <PlusSquare
                fill="#2A2F99"
                color="white"
                size={35}
                style={{ display: "inline" }}
              />
              Agregar otro subcliente
            </div>
          ),
          fields: [],
        },
      ];
  };
  const fetchData = useCallback(async (idService) => {
    const { ok, data } = await Request("/service/" + idService);
    if (ok) {
      const fetchedService = {
        ...data,
        editTemplate: serviceSchema.editTemplate,
        serviceConcepts: data.serviceConcepts.map((sc) => ({
          ...sc,
          absenceDiscount: sc.absenceDiscount ? sc.absenceDiscount : 0,
        })),
      };
      setAllData(fetchedService);
    }
  }, []);
  useEffect(() => {
    if (serviceData.idService && isOpen) {
      fetchData(serviceData.idService);
    }
  }, [serviceData, isOpen]);
  return (
    <Modal size="xl" isOpen={isOpen} style={{ maxWidth: "90%" }}>
      <ModalHeader toggle={toggle}>
        <div
          style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
        >
          EDICIÓN DE SERVICIO
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={iconoPlantillaServices} style={{ width: "5rem" }} />
            <div
              style={{
                marginLeft: "1rem",
                fontWeight: 600,
                color: "#2A2F99",
              }}
            >
              {serviceData.nameService}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 flex gap-3 mb-3">
          {editServiceBasicDataSchema(data, { onDataChange }).map(
            (inputSchema) => (
              <DgInput {...inputSchema} />
            )
          )}
        </div>
        <DgForm
          data={data}
          classNameGrid="grid grid-cols-2 gap-4"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <ModalFooter>
            <div className="grid grid-cols-3 gap-5">
              <div />
              <DgButton type="submit" color="primary" onClick={onConfirm}>
                Actualizar
              </DgButton>
              <DgButton onClick={toggle}>Cerrar</DgButton>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
    </Modal>
  );
};
export default ModalServiceDetails;
