import { useEffect } from "react";
import { useState } from "react";
import { Request } from "../helpers/api";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import Swal from "sweetalert2";
import {
  emailBodyToString,
  formatAndAppendFileListForFormData,
} from "../helpers/utils";
import { useSelector } from "react-redux";

function useGetEmailParams(url, initialData) {
  const loggedInUser = useSelector((state) => state.auth.data.username);

  const [emailData, setEmailData] = useState({ ...initialData });
  const handleChange = ({ target: { name, value, checked, type } }) => {
    setEmailData({
      ...emailData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const fetchData = async () => {
    const { ok, data } = await Request(url);
    if (ok) {
      const {
        folioCfdi = "",
        ccToSendCollectionEmail: cc,
        clientContacts,
      } = data;
      const loggedInEmailSenderId = data.emailAccountConfigs.find(
        ({ user }) => user === loggedInUser
      );
      const defaultMessage = convertFromHTML(data.clientEmailTemplateBill);
      const emailBody = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          defaultMessage.contentBlocks,
          defaultMessage.entityMap
        )
      );

      setEmailData({
        ...emailData,
        ...data,
        internalFolio: folioCfdi,
        body: emailBody,
        cc,
        clientContacts,
        idEmailAccountConfig: loggedInEmailSenderId?.idEmailAccountConfig,
      });
    }
  };
  const handleSubmit = async (toggle, setConfirmationModalOpen, setIsLoading, endPoint) => {
    const emailBody = emailData.body;
    setIsLoading(true);
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        ...emailData,
        internalFolio: `000${emailData.internalFolio}`,
        body: emailBody ? emailBodyToString(emailBody) : "",
      })
    );
    formatAndAppendFileListForFormData(formData, emailData.files);
    const { ok, envio, msg } = await Request(
      endPoint,
      formData,
      "POST",
      true,
      true
    );
    ok
      ? Swal.fire({
        title: "Enviar e-mail",
        html: `${envio.data.accepted.length
          ? `Destinatarios aceptados: </br> ${envio.data.accepted.map(
            (user, index) => user + "</br>"
          )}`
          : ""
          } 
            ${envio.data.rejected.length
            ? `Destinatarios rechazados: </br> ${envio.data.rejected.map(
              (user, index) => user + "</br>"
            )}`
            : ""
          }`,
        icon: "success",
      })
      : Swal.fire("Enviar e-mail", msg, "error");
    setIsLoading(false);
    setConfirmationModalOpen(false);
    toggle(false);
  };

  useEffect(() => {
    fetchData();
  }, [url]);
  return [emailData, setEmailData, handleChange, handleSubmit];
}

export default useGetEmailParams;
