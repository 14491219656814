import React, { useEffect, useState } from "react";
import DgForm from "../../../components/DgForm";
import { useForm } from "../../../helpers/useForm";
import Button from "../../../components/Button";
import {
  conceptSchema,
  serviceSchema,
  subClientSchema,
} from "../../../helpers/schemas";
import { Eye, EyeOff, PlusSquare } from "lucide-react";
import { Request } from "../../../helpers/api";
import "./NewService.css";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  handleNestedChange,
  parseCat,
  handleNestedItems,
} from "../../../helpers/utils";
import { useLocation, useParams } from "react-router-dom";
import {
  addressInputs,
  conceptsSchema,
  subclientsSchema,
} from "../../../helpers/formUtils";
import useGetZipCode from "../../../helpers/useGetZipCode";
import { clientTypes } from "../../../helpers/enums";
import DgLoader from "../../../components/DgLoader";
import Title from "../../../components/Title";

const NewService = ({ setLoading, onSuccessNavigation }) => {
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);
  const [showStreetForm, setShowStreetForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id: idClient } = useParams();
  const {
    serviceConcepts: catConcepts,
    catRegions: regions,
    catSubRegions: subRegions,
    CatRegime,
    CatCfdi,
    CatPaymentWay,
  } = useSelector((s) => s.catalogues);

  const [data, onDataChange, clearData, setAllData] = useForm({
    ...serviceSchema,
    serviceConcepts: [
      {
        absenceDiscount: 0,
        description: "",
        idCatServiceConcept: "",
        idService: "",
        price: "",
        quantity: "",
      },
    ],
  });

  const [handleZipChange, suburbs] = useGetZipCode(
    data.postalCodeService,
    data,
    setAllData,
    "Service"
  );
  const fetchFiscalPeriods = async (isMonthly) => {
    const { ok, data: fiscalData } = await Request(
      `/user/fiscalPeriods?limit=6&all=false`
    );
    if (ok) {
      isMonthly
        ? setFiscalPeriodList(
          fiscalData.filter((fiscalPeriod) => fiscalPeriod.montlhyName)
        )
        : setFiscalPeriodList(fiscalData);
    }
  };
  const getFields = () => {
    const getConceptFields = () => {
      let fields = [];
      data.serviceConcepts.forEach((nestedData, nestedIndex) => {
        const onNestedChange = (event) => {
          handleNestedChange(event, nestedIndex, "serviceConcepts", data, {
            onDataChange,
            handleZipChange,
          });
        };
        fields = fields.concat(
          conceptsSchema(
            data,
            nestedData,
            nestedIndex,
            { onNestedChange, onDataChange },
            { catConcepts }
          )
        );
      });
      return fields;
    };

    const getSubClientsFields = () => {
      let fields = [];
      data.detailService.forEach((nestedData, nestedIndex) => {
        const onNestedChange = (event) => {
          handleNestedChange(event, nestedIndex, "detailService", data, {
            onDataChange,
            handleZipChange,
          });
        };
        fields = fields.concat(
          subclientsSchema(
            data,
            nestedData,
            nestedIndex,
            { onNestedChange, handleNestedItems, onDataChange },
            { CatRegime, CatCfdi, CatPaymentWay, suburbs }
          )
        );
      });
      return fields;
    };

    return [
      {
        fields: [
          {
            label: "Nombre comercial del servicio",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.name,
            minLength: "5",
            required: true,
            iconName: "ClipboardList",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Periodicidad del pago",
            placeholder: "Periodicidad del pago",
            type: "select",
            name: "paymentPeriod",
            value: data.paymentPeriod,
            options: [
              { label: "Selecciona un elemento", value: "" },
              { label: "Quincenal", value: "BIWEEKLY" },
              { label: "Mensual", value: "MONTHLY" },
            ],
            required: true,
            iconName: "DollarSign",
            errorMessage: "Seleccione una opción",
          },
          {
            label: "Periodicidad de facturación",
            placeholder: "Periodicidad de facturación",
            type: "select",
            name: "invoicePeriod",
            value: data.invoicePeriod,
            options: [
              { label: "Selecciona un elemento", value: "" },
              { label: "Quincenal", value: "BIWEEKLY" },
              { label: "Mensual", value: "MONTHLY" },
            ],
            required: true,
            iconName: "DollarSign",
            errorMessage: "Seleccione una opción",
          },
          {
            label: "Periodo inicial",
            placeholder: "Periodo inicial",
            type: "select",
            name: "idFiscalPeriod",
            value: data.idFiscalPeriod,
            required: true,
            options: [
              { value: "", label: "Selecciona un elemento" },
              ...parseCat(
                fiscalPeriodList,
                data.invoicePeriod === "MONTHLY" ? "montlhyName" : "name",
                "idFiscalPeriod"
              ),
            ],
            iconName: "Calendar",
            errorMessage: "Seleccione una opción",
          },
          {
            label: "Tipo de servicio",
            placeholder: "FISCAL",
            type: "select",
            name: "clientTypeService",
            value: data.clientTypeService,
            required: true,
            options: clientTypes,
            iconName: "User",
            errorMessage: "Seleccione una opción",
          },
          {
            label: "Región",
            placeholder: "Región",
            type: "select",
            name: "idCatRegion",
            required: false,
            value: data.idCatRegion,
            options: [
              { value: "", label: "Elige una región" },
              ...parseCat(regions, "name", "idCatRegion"),
            ],
            iconName: "MapPin",
          },
          {
            label: "Sub-región",
            placeholder: "Región",
            type: "select",
            name: "idCatSubregion",
            required: false,
            value: data.idCatSubregion,
            options: [
              { value: "", label: "Elige una sub-región" },
              ...parseCat(subRegions, "name", "idCatSubregion"),
            ],
            iconName: "MapPin",
          },
          {
            label: "Recurrencia",
            placeholder: "Es recurrente",
            type: "checkbox",
            name: "isRecurring",
            value: data.isRecurring,
            errorMessage: "Seleccione una opción",
            onInputChange: (e) => {
              onDataChange({
                target: { name: "isRecurring", value: e.target.checked },
              });
            },
          },
          // {
          //   label: "Agrupar a servicio de vigilancia",
          //   placeholder: "Agrupar",
          //   type: "checkbox",
          //   name: "applySecurityService",
          //   value: data.applySecurityService,
          //   errorMessage: "Seleccione una opción",
          //   onInputChange: (e) => {
          //     onDataChange({
          //       target: { name: "applySecurityService", value: e.target.checked },
          //     });
          //   },
          // },          
        ],
      },
      {
        title: "CONCEPTOS",
        withAdd: true,
        addElement: (
          <div
            onClick={() => {
              handleNestedItems(
                data,
                conceptSchema,
                {
                  onDataChange,
                },
                "serviceConcepts"
              );
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        ),
        groupClassNameGrid: "conceptsList",
        fields: getConceptFields(),
      },
      {
        title: "SUBCLIENTES",
        withAdd: true,
        addElement: (
          <div
            onClick={() => {
              if (document.getElementsByName("nameDetailService").length < 1) {
                handleNestedItems(
                  data,
                  subClientSchema,
                  {
                    onDataChange,
                  },
                  "detailService"
                );
              }
              handleNestedItems(
                data,
                subClientSchema,
                {
                  onDataChange,
                },
                "detailService"
              );
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro subcliente
          </div>
        ),
        fields: getSubClientsFields(),
        separator: (
          <div style={{ background: "red", width: "100%", height: 2 }}></div>
        ),
      },
      {
        title: "DOMICILIO",
        titleRightElement: showStreetForm ? (
          <EyeOff
            style={{
              cursor: "pointer",
              marginTop: "2rem",
              color: "rgb(42 47 153)",
            }}
            onClick={() => setShowStreetForm((currentState) => !currentState)}
          />
        ) : (
          <Eye
            style={{
              cursor: "pointer",
              marginTop: "2rem",
              color: "rgb(42 47 153)",
            }}
            onClick={() => setShowStreetForm((currentState) => !currentState)}
          />
        ),
        fields: showStreetForm
          ? [...addressInputs(data, suburbs[0], "Service")]
          : [],
      },
    ];
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(
      "/service",
      {
        ...data,
        idClient,
        distributeAmounts: data.detailService.length ? true : false,
      },
      "POST"
    );
    setIsLoading(false);
    if (res.ok) {
      Swal.fire("Éxito", "Servicio registrado con éxito.", "success").then(
        ({ isConfirmed }) => {
          isConfirmed && onSuccessNavigation();
        }
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    handleZipChange();
  }, [data.postalCodeService]);

  useEffect(() => {
    setAllData({
      ...serviceSchema,
      serviceConcepts: [
        {
          absenceDiscount: 0,
          description: "",
          idCatServiceConcept: "",
          idService: "",
          price: "",
          quantity: "",
        },
      ],
      detailService: [],
    });
  }, []);

  useEffect(() => {
    fetchFiscalPeriods(data.invoicePeriod === "MONTHLY");
  }, [data.invoicePeriod]);

  return (
    <>
      <Title className={"pt-4"}>Alta de Servicio</Title>
      <DgForm
        zipError={data.clientTypeService === "NONFISCAL" ? false : data.error}
        data={data}
        classNameGrid="grid grid-cols-4 gap-4"
        onChange={onDataChange}
        onSubmit={handleSubmit}
        groups={getFields()}
      >
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button type="submit">Dar de Alta</Button>
        </div>
      </DgForm>
      <DgLoader open={isLoading} />
    </>
  );
};

export default NewService;
