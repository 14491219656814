import { type } from "@testing-library/user-event/dist/type";
import { BASE_URL, Request } from "./api";
import { googleGeoBaseApi, googleGeoKey, TODAY } from "./consts";
import { operatingYear } from "./enums";
import { convertFromHTML, convertToRaw } from "draft-js";
import axios from "axios";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";

export const noop = () => {};

export const parseCat = (arr, strTxt, strVal, condition, addedStrTxt) => {
  if (arr && condition) {
    return arr
      .filter((n) => n[condition])
      .map((n) => {
        return {
          label: addedStrTxt ? n[addedStrTxt] + " " + n[strTxt] : n[strTxt],
          value: n[strVal],
        };
      });
  } else if (arr) {
    return arr.map((n) => {
      return {
        label: addedStrTxt ? n[addedStrTxt] + " " + n[strTxt] : n[strTxt],
        value: n[strVal],
      };
    });
  }
  return [];
};

export const sanitizeDate = (date) => {
  return date ? new Date(date.split("T")[0] + "T12:00:00") : null;
};

export const parseObjectDate = (date, format = 1, bugged) => {
  const monthsEs = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  if (date) {
    let r;
    switch (format) {
      case 1: // DD/MM/YYYY
        r = [
          (bugged ? date.getDate() + 1 : date.getDate())
            .toString()
            .padStart(2, "0"),
          (date.getMonth() + 1).toString().padStart(2, "0"),
          date.getFullYear(),
        ].join("/");
        break;
      case 2: // Dia Mes YY
        r = [
          (bugged ? date.getDate() + 1 : date.getDate())
            .toString()
            .padStart(2, "0"),
          monthsEs[date.getMonth()],
          date.getFullYear().toString().substring(2, 4) + "'",
        ].join(" ");
        break;

      case 3: // DD/MM/YYYY
        r = [
          (bugged ? date.getDate() + 1 : date.getDate())
            .toString()
            .padStart(2, "0"),
          (date.getMonth() + 1).toString().padStart(2, "0"),
          date.getFullYear(),
        ].join("/");
        break;
      case 4: // YYYY-MM-DD
        r = [
          date.getFullYear(),
          (date.getMonth() + 1).toString().padStart(2, "0"),
          (bugged ? date.getDate() + 1 : date.getDate())
            .toString()
            .padStart(2, "0"),
        ].join("-");
        break;
      default:
    }
    return r;
  }
};

export const getAge = (dateStr) => {
  var today = new Date();
  var birthDate = new Date(dateStr);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const downloadBlobFile = (blobRes, fileName = "untitled.txt") => {
  if (blobRes) {
    const url = window.URL.createObjectURL(new Blob([blobRes]));
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = url;
    link.click();
  }
};

export const formatPhone = (phone) => {
  const clearValue = phone.replaceAll("-", "");
  const formattedValue =
    clearValue.length === 10
      ? [
          clearValue.substring(0, 2),
          clearValue.substring(2, 6),
          clearValue.substring(6, 10),
        ].join("-")
      : phone;
  return formattedValue;
};

export const handlePhoneChange = async (e, onDataChange) => {
  const { value, name } = e.target;
  onDataChange({ target: { name, value: formatPhone(value) } });
};

export const getGeolocationData = async (zipCode, defaultData) => {
  if (zipCode?.length > 4) {
    const res = await (
      await fetch(
        //Country hardcoded to MX for now
        `${googleGeoBaseApi}?address=${zipCode},MX&key=${googleGeoKey}&language=es`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
    ).json();
    if (res.status === "OK" && res.results[0].postcode_localities) {
      const { address_components = [], postcode_localities: localities = [] } =
        res.results[0];
      const state = address_components.find((ac) =>
        ac.types.find((t) => t === "administrative_area_level_1")
      );
      const municipality = address_components.find((ac) => {
        return ac.types.find((t) => /sublocality/.test(t));
      });
      if (defaultData) {
        return {
          address_components,
          localities: localities.map((l) => ({ label: l, value: l })),
          state: state ? state.long_name : defaultData.state,
          municipality: municipality
            ? municipality.long_name
            : defaultData.municipality,
        };
      } else {
        return {
          address_components,
          localities: localities.map((l) => ({ label: l, value: l })),
          state: state ? state.long_name : null,
          municipality: municipality ? municipality.long_name : null,
        };
      }
    }
  }

  return {
    address_components: [],
    localities: [],
    state: defaultData ? defaultData.state : null,
    municipality: defaultData ? defaultData.municipality : null,
    suburb: null,
    error: true,
  };
};

export const searchInObjectArray = (searchValue, searchArray, searchFields) => {
  const searchLower = searchValue.toLowerCase();
  const filteredData = [];
  searchArray.forEach((d) => {
    let found = false;
    searchFields.forEach((k) => {
      const val = d[k] ? d[k].toLowerCase() : "";
      if (val.indexOf(searchLower) !== -1) {
        found = true;
      }
    });
    if (found) {
      filteredData.push(d);
    }
  });
  return filteredData;
};

export const getColorPayment = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#F88F14";
      break;
    case 2:
      color = "#C82B2B";
      break;
    case 3:
      color = "#0CBE13";
      break;
    case 5:
      color = "#C82B2B";
      break;
    case 6:
      color = "#DCD40A";
      break;
    default:
      color = "#000";
      break;
  }
  return color;
};

export const getColorInvoice = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#F88F14";
      break;
    case 2:
      color = "#30B6FF";
      break;
    case 3:
      color = "#30B6FF";
      break;
    case 4:
      color = "#C82B2B";
      break;
    default:
      color = "#000";
  }
  return color;
};

export const getColorPaymentComplement = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#333333";
      break;
    case 2:
      color = "#F88F14";
      break;
    case 3:
      color = "#30B6FF";
      break;
    default:
      color = "#000";
  }
  return color;
};

export const getOperatingYearsCat = () => {
  let r = [];
  for (let i = operatingYear; i <= TODAY.getFullYear()+1; i++) {
    r.push({ y: i });
  }
  return parseCat(r, "y", "y");
};

export const triggerPdfDownload = (urls = []) => {
  urls.forEach((fileURL) => {
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    } else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileURL);
      _window.close();
    }
  });
};

export const downloadBlobRes = async (url, fileName, setLoader) => {
  setLoader && setLoader(true);
  const res = await Request(
    `${BASE_URL}${url}`,
    null,
    "GET",
    false,
    false,
    true
  );
  setLoader && setLoader(false);

  if (res) {
    downloadBlobFile(res, fileName || "Factura.pdf");
  }
};

export const parseInvoiceErrors = (errOb) => {
  let strRes = "";
  if (errOb) {
    if (typeof errOb === "string") {
      strRes = errOb;
    } else {
      const Message = errOb.Message;
      const ModelState = errOb.ModelState;
      strRes = Message;
      if (ModelState && typeof ModelState === "object") {
        Object.keys(ModelState).forEach((k) => {
          const obProp = ModelState[k];
          if (typeof obProp === "string") {
            strRes += ", " + obProp;
          } else if (Array.isArray(obProp)) {
            obProp.forEach((p) => {
              if (typeof p) {
                strRes += "<br>" + p;
              }
            });
          }
        });
      }
    }
  }

  return strRes;
};

export const getPermissionsList = (selector) => {
  const {
    auth: {
      data: { idCatRole },
    },
    catalogues: { RoleActionsPermissions = [] },
  } = selector((s) => s);

  return RoleActionsPermissions.filter((p) => p.idCatRole === idCatRole).map(
    (p) => p.idCatAction
  );
};

export const searchArrayInArray = (needle, seed) => {
  let res = false;
  needle.forEach((n) => {
    res = res || seed.includes(n);
  });
};

export const jToFormData = (json) => {
  const r = new FormData();
  Object.keys(json).forEach((k) => {
    r.append(k, json[k]);
  });
  return r;
};

export const objetctToQueryStr = (ob = {}) => {
  return Object.keys(ob)
    .map((k) => `${k}=${ob[k]}`)
    .join("&");
};

export const getPermissions = (permissionsArray) => {
  const finalPermsArray = [];
  if (permissionsArray) {
    permissionsArray.forEach((permission) => {
      if (permission.visibility) {
        finalPermsArray.push(permission.idCatAction);
      }
    });
  }
  return finalPermsArray;
};

export const permissionCheck = (permissionsArray, permissionName) => {
  const filteredPermission = permissionsArray?.filter(
    (permission) => permission.name === permissionName
  );
  const visibility = filteredPermission[0]?.visibility;

  return visibility;
};

export const lowerCaseExceptFirst = (string) => {
  const words = string.split(" ");

  const result = words.map((word) => {
    if (word.length > 2) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word.toLowerCase();
    }
  });

  return result.join(" ");
};

export const removeWhiteSpaces = (string) => string.replace(/\s/g, "");

export const formatEmailList = (emailsStringList) =>
  emailsStringList
    ? emailsStringList.split(",").map((email) => removeWhiteSpaces(email))
    : "";

export const isValidEmail = (email) => {
  // Regular expression pattern to validate an email address
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Use the test() method to check if the email matches the pattern
  return pattern.test(email);
};

export const formatAndAppendFileListForFormData = (formData, fileList) => {
  if (fileList.length) {
    for (let i = 0; i < fileList.length; i++) {
      formData.append("files", fileList[i]);
    }
  }
};

export const handleNestedChange = (
  event,
  nestedIndex,
  propertyName,
  data,
  handlers
) => {
  const { onDataChange, handleZipChange } = handlers;
  const { value, name, type } = event.target;
  const checked = event.target?.checked;

  const arr = data[propertyName];
  const item = arr[nestedIndex];

  item[name] = type === "checkbox" ? checked : value;
  arr[nestedIndex] = item;

  onDataChange({ target: { name: propertyName, value: arr } });
  name === "postalCode" &&
    handleZipChange({ index: nestedIndex, property: propertyName, cp: value });
};

export const handleNestedItems = (
  data,
  schema,
  handlers,
  propertyName,
  willDelete = false,
  nestedIndex = null
) => {
  const { onDataChange } = handlers;
  const arr = data[propertyName];

  willDelete ? arr.splice(nestedIndex, 1) : arr.push({ ...schema });

  onDataChange({ target: { name: propertyName, value: arr } });
};

export const defaultServiceEmail = (folio) => {
  return convertFromHTML(
    folio
      ? `Estimado cliente:<br>Aprovecho su atención para brindarle un cordial saludo, al mismo tiempo que ponemos a su alcance su factura electrónica.<ul><li><b>CFD-${folio}</b></li></ul><br>Favor de enviar confirmación de recibido y aviso de pago a correo:<br><br> amedina@sexmexico.com &nbsp; &nbsp; C.P. Alejandrina Medina. <br> bestrada@sexmexico.com &nbsp; &nbsp; Beatriz Estrada.`
      : `<br><br> amedina@sexmexico.com &nbsp; &nbsp; C.P. Alejandrina Medina. <br> bestrada@sexmexico.com &nbsp; &nbsp; Beatriz Estrada.`
  );
};

export const emailBodyToString = (emailBody) =>
  draftToHtml(convertToRaw(emailBody?.getCurrentContent()));

export const handleDownloadClick = async (
  fileUrl,
  toggleLoader = () => {},
  fileName = null
) => {
  try {
    let { name, extension } = getFileDataFromURL(fileUrl);
    toggleLoader(true);
    const response = await axios.get(fileUrl, { responseType: "blob" });
    toggleLoader(false);

    // Create a temporary anchor element to trigger the download
    const blob = new Blob([response.data], {
      type: `application/${extension}`,
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName || name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    toggleLoader(false);
    Swal.fire("Error de descarga", error.message, "error");
  }
};

export const getFileDataFromURL = (url) => {
  let fileData;
  let name = url.split("/");
  let extension = url.split(".");

  name = name[name.length - 1];
  extension = extension[extension.length - 1];
  fileData = { name, extension };

  return fileData;
};

export const getMxFormatNumber = (number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  return number.toString().replace(exp, rep);
};

export const formatDate = (fecha) => {
  // Crear un objeto Date utilizando la cadena de fecha y asegurarse de que se interprete en UTC
  var fechaObj = new Date(fecha + "T00:00:00Z");

  // Obtener día, mes y año
  var dia = ("0" + fechaObj.getUTCDate()).slice(-2);
  var mes = ("0" + (fechaObj.getUTCMonth() + 1)).slice(-2);
  var anio = fechaObj.getUTCFullYear();

  // Construir la fecha en formato dd/mm/aaaa
  const date = dia + "/" + mes + "/" + anio;
  return date;
};

export const formatThousands = (value) => {
  const cleanedValue = value?.replace(/[^\d.]/g, "");
  const numericValue = parseFloat(cleanedValue);
  let formattedValue = value;

  if (!isNaN(numericValue)) {
    formattedValue = numericValue?.toLocaleString("en-US", {
      maximumFractionDigits: 4,
    });
  }

  return formattedValue;
};

export const unformatThousands = (formattedNumber) => {
  // Remove commas
  const numberWithCommasRemoved = formattedNumber?.replace(/,/g, "");

  
  // Convert the string to a number
  const unformattedNumber = parseFloat(numberWithCommasRemoved);

  return isNaN(unformattedNumber) ? 0 : unformattedNumber;
};
