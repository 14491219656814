import React, { useEffect, useRef, useState } from "react";
import DgModal from "../../../components/DgModal";
import DgInput from "../../../components/DgInput";
import FormTitle from "../../../components/FormTitle";
import { useForm } from "../../../helpers/useForm";
import Concepts from "../Concepts";
import useGetScreenWidth from "../../../helpers/useGetScreenWidth";
import { enumClientTypes, enumPaymentMethods } from "../../../helpers/enums";
import EmptyCard from "../../../components/EmptyCard";
import iconoDetalle from "../../../css/img/IconoDetalleCliente.svg";
import { Plus, Trash } from "lucide-react";
import "./ModalEvents.css";
import { Tooltip } from "@mui/material";
import FiltrableDataList from "../../../components/FiltrableDataList";
import { Request } from "../../../helpers/api";
import { parseCat, parseInvoiceErrors } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { serviceConceptsSchema } from "../../../helpers/formUtils";
import DgLoader from "../../../components/DgLoader";
import { handleInvoice } from "../handlers";
import PostInvoiceModal from "./PostInvoiceModal";

function ModalEvents({ open, toggle, clients, fetchData }) {
  const [cfdiList, setCfdiList] = useState([]);
  const [postModalInvoiceOpen, setPostModalInvoiceOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [width] = useGetScreenWidth();
  const [conceptsAmount, setConceptsAmount] = useState(0);
  const [formattedClients, setFormattedClients] = useState([]);
  const [client, setClient] = useState({});
  const [formValues, handleInputChange, reset, setValues] = useForm({
    serviceConcepts: [],
    paymentMethod: "PUE",
    idCatRegion: "",
    idClient: "",
  });
  const { catRegions } = useSelector((s) => s.catalogues);
  const fetchClient = async (idClient) => {
    const res = await Request(`/client/${idClient}`);
    if (res.ok) {
      setClient(res.data);
    }
  };


  const cleanAllData = () => {
    reset();
    setClient({});
    setConceptsAmount(0);
  };

  const handleNestedChange = (e, ix) => {
    console.log("conceptsAmount",conceptsAmount)
    const { value, name } = e.target;
    console.log("name",name)
    console.log("value",value)
    console.log("e.target",e.target.type)
    const arr = formValues.serviceConcepts;

    if(e.target.type=="checkbox"){
      arr[ix] = {
        ...serviceConceptsSchema,
        ...arr[ix],
        [name]: e.target.checked,
      };
    }else{
    arr[ix] = {
      ...serviceConceptsSchema,
      ...arr[ix],
      [name]: value,
    };      
    }


    setValues({
      ...formValues,
      serviceConcepts: arr,
    });
  };

  const addConcept = () =>
    setConceptsAmount((conceptsAmount) => conceptsAmount + 1);
  const removeConcept = () => {
    setConceptsAmount((conceptsAmount) => conceptsAmount - 1);
    setValues((formValues) => {
      return {
        ...formValues,
        serviceConcepts: formValues.serviceConcepts.splice(
          formValues.serviceConcepts.length - 1,
          1
        ),
      };
    });
  };
  const sendConcept = async () => {
    const concept = {
      ...formValues,
      idClient: client.idClient,
    };
    setIsLoading(true);
    const { objupdateafacturar, ok, error } = await Request(
      "/invoice/event",
      concept,
      "POST"
    );
    setIsLoading(false);
    if (ok) {
      handleInvoice(objupdateafacturar, () => {
        cleanAllData();
        fetchData();
        setCfdiList(objupdateafacturar);
        setPostModalInvoiceOpen(true);
      });
    } else Swal.fire("Error", error || "", "error");
  };

  useEffect(() => {
    setFormattedClients(() =>
      clients.map((client) => {
        const formattedClient = {
          label: client.name,
          value: client.idClient,
        };
        return formattedClient;
      })
    );
  }, [clients]);
  useEffect(() => {
    cleanAllData();
  }, [open]);

  return (
    <DgModal
      style={{
        width: "fit-content",
        maxHeight: "90%",
        overflow: "auto",
        position: "relative",
        backgroundColor: "#FFFFFF",
      }}
      open={open}
      toggle={() => toggle((currentState) => !currentState)}
      withSend={{
        title:
          formValues.clientTypeDetailService === "NONFISCAL"
            ? "Remisionar"
            : "Facturar",
        action: sendConcept,
      }}
      onClose={() => setClient({})}
    >
      <FormTitle
        style={{ fontSize: 25, marginBottom: 20 }}
        title="Facturar evento"
      />
      <div
        style={{
          display: "flex",
          maxWidth: width <= 1400 ? width * 0.7 : width * 0.5,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "0px 20px",
        }}
      >
        <EmptyCard
          containerStyle={{
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.411)",
            gap: 10,
            paddingRight: 20,
          }}
        >
          <img
            src={iconoDetalle}
            alt="manInSuit"
            style={{ height: 100, aspectRatio: 1 }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <FiltrableDataList
              containerStyle={{ width: "100%" }}
              required
              label="Cliente"
              options={formattedClients}
              iconName={"Users"}
              onInputChange={({ target: { value } }) => {
                if (typeof value === "number") fetchClient(value);
              }}
            />
            <DgInput
              containerStyle={{
                width: "100%",
                marginTop: 0,
              }}
              type="select"
              name="clientTypeDetailService"
              label="Tipo de evento"
              options={enumClientTypes}
              iconName="FormInput"
              onChange={handleInputChange}
            />
            <DgInput
              disabled={!client.paymentMethod}
              containerStyle={{ width: "100%", marginTop: 0 }}
              name="name"
              label="Nombre de evento"
              iconName="FormInput"
              onChange={handleInputChange}
            />

            <DgInput
              containerStyle={{
                width: width < 1400 ? "100%" : "48%",
                marginTop: 0,
                position: "relative",
                left: width > 1400 && "-1%",
              }}
              type="select"
              name="paymentMethod"
              label="Método de pago"
              options={enumPaymentMethods}
              iconName="FormInput"
              value={formValues.paymentMethod}
              onChange={handleInputChange}
            />
            <DgInput
              containerStyle={{
                width: width < 1400 ? "100%" : "48%",
                marginTop: 0,
              }}
              type="select"
              name="idCatRegion"
              label="Región"
              iconName="FormInput"
              options={[
                { value: "all", label: "Todos" },
                ...parseCat(catRegions, "name", "idCatRegion"),
              ]}
              onChange={handleInputChange}
            />
            {/* <DgInput
              containerStyle={{ width: "100%", marginTop: 0 }}
              name="applySecurityService"
              label="Agrupar a servicio de vigilancia"
              // iconName="FormInput"
              type="checkbox"
              onChange={handleInputChange}
            /> */}
            <p>&nbsp;</p>
          </div>
        </EmptyCard>
        {Array.from({ length: conceptsAmount }).map((_, index) => (
          <div
            style={{
              position: "relative",
              paddingBottom: 20,
            }}
          >
            {index === conceptsAmount - 1 && (
              <div
                onClick={removeConcept}
                className="removeConceptEventModal"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "red",
                  fontWeight: 700,
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                }}
              >
                <Trash />
                Remover concepto
              </div>
            )}
            <Concepts
              className="eventConcept"
              index={index}
              key={index}
              style={{
                margin: "20px 0px",
                gap: 10,
                paddingBottom: 20,
              }}
              onChange={handleNestedChange}
            />
          </div>
        ))}
      </div>
      <Tooltip onClick={addConcept} title="Agregar concepto">
        <div
          className="addConceptEventModal"
          style={{
            display: "flex",
            position: "fixed",
            bottom: "10%",
            right: "10%",
            background: "rgb(42, 47, 153)",
            borderRadius: 100,
            cursor: "pointer",
            transition: "0.3s",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Plus style={{ height: "80%", width: "80%" }} color="#ffffff" />
        </div>
      </Tooltip>
      <DgLoader open={isLoading} />
      <PostInvoiceModal
        data={cfdiList}
        open={postModalInvoiceOpen}
        togle={() => setPostModalInvoiceOpen((prevState) => !prevState)}
        withSend={{
          title: "Cerrar",
          action: () => {
            toggle();
            setPostModalInvoiceOpen(false);
          },
        }}
      />
    </DgModal>
  );
}

export default ModalEvents;
