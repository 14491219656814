import React, { useCallback, useEffect, useState } from "react";
import { downloadBlobRes, parseCat } from "../helpers/utils";
import { useSelector } from "react-redux";
import { useForm } from "../helpers/useForm";
import { Request } from "../helpers/api";

function useGetClientListData() {
  const [filters, handleFilters] = useForm({
    clientType: "",
    region: "",
    viewType: "TRADENAME",
  });
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const regions = useSelector((state) => [
    { label: "Todos", value: "" },
    ...parseCat(state.catalogues.catRegions, "name", "idCatRegion"),
  ]);
  const fetchData = useCallback(async ({ clientType, region, viewType }) => {
    const res = await Request(
      `/client/service?clientType=${clientType}&idCatRegion=${region}&viewType=${viewType}`
    );
    if (res.ok) {
      const allClients = res.data;
      setData(allClients);
      setAllData(allClients);
    }
  }, []);

  const actions = {
    downloadServicesTemplatesReport: async () => {
      downloadBlobRes(
        `/report/tradeName`,
        "Reporte de plantillas de servicios.xlsx",
        setIsLoading
      );
    },
    downloadClientsReport: async () => {
      downloadBlobRes(
        `/report/clients`,
        "Reporte de clientes.xlsx",
        setIsLoading
      );
    },
    handleSearch: (value) => {
      setSearch(value);
      const searchLower = value.toLowerCase();
      const searchFields = [
        "clientNumber",
        "tradeName",
        "serviceNumber",
        "nameClient",
        "status",
      ];
      const filteredData = allData.filter((clientData) => {
        let found = false;
        searchFields.forEach((searchField) => {
          const clientField = clientData[searchField];
          const isNotNull = clientField.length > 0;
          if (isNotNull) {
            if (clientField.toLowerCase().includes(searchLower)) {
              found = true;
            }
          }
        });
        return found;
      });
      setData(filteredData);
    },
    handleFilters,
    fetchData: () => fetchData(filters),
  };

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  return [{ search, data, regions, filters }, actions, isLoading];
}

export default useGetClientListData;
