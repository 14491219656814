import React, { useState } from "react";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import DgTable from "../../components/DgTable";
import { getColumnsClients } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import ModalPlantillaServices from "./ModalPlantillaServices";
import { clientTypes, enumViewTypes } from "../../helpers/enums";
import DgLoader from "../../components/DgLoader";
import { TbFileDownload } from "react-icons/tb";
import { BsPersonDown } from "react-icons/bs";
import useGetClientListData from "../../hooks/useGetClientListData";
import ModalCancelarPlantillaServices from "./ModalCancelarPlantillaServices";
import ModalPlantillaServicesFromClientTable from "./ModalPlantillaServicesFromClientTable";
import ModalCancelarPlantillaServicesFromClientTable from "./ModalCancelarPlantillaServicesFromClientTable";

const ListClient = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenCancelTemplate, setModalOpenCancelTemplate] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [state, actions, isLoading] = useGetClientListData();
  const [loading, setLoading] = useState(false);

  

  const refreshTableClient = (e) => {
    actions.fetchData();
  };


  const funcs = {
    service: () => {},
    details: ({ idClient }) => {
      navigate("/detalle-cliente/" + idClient);
    },
    serviceDetails: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    mail: (row) => {
      Swal.fire({
        title: "Estado de cuenta",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Enviar",
        confirmButtonColor: "#7066e0",
        denyButtonColor: "#7066e0",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Request(
            "/report/sendAccountStatusEmail/" + row.idClient
          );
          if (res.ok) {
            Swal.fire("Éxito", "El reporte ha sido enviado.", "success");
          }
        } else if (result.isDenied) {
        }
      });
    },
    deactivate: async (row) => {
      Swal.fire({
        // title: "¿Confirma la siguiente operación?",
        // html: `Se dará de ${row.isActive ? "baja" : "alta"} al cliente ${
        //   row.nameClient
        // }`,
        html: `
        <hr style="border: 1px solid #ccc; margin:0; margin-bottom: 5px;">
          <strong class="strongalert">Notificación de Advertencia</strong>
          <p class="parrafoalert">Está a punto de desactivar un cliente. Una vez desactivado, no se podrán generar servicios y se cancelarán los servicios existentes.</p>
          <p class="questionoalert">¿Está seguro de que desea continuar?</p>
        `,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Request(
            `/client/inactivate/${row.idClient}`,
            { isActive: !row.isActive },
            "PATCH"
          );
          if (res.ok) {
            Swal.fire(
              "Éxito",
              `Cliente ${row.isActive ? "desactivado" : "activado"} con éxito.`,
              "success"
            );
          } else {
            Swal.fire("Error", res.error, "error");
          }
          actions.fetchData();
        }
      });
    },
    activateClient: async (row) => {
      Swal.fire({
        // title: "¿Confirma la siguiente operación?",
        // html: `Se dará de ${row.isActive ? "baja" : "alta"} al cliente ${
        //   row.nameClient
        // }`,
        html: `
        <hr style="border: 1px solid #ccc; margin:0; margin-bottom: 5px;">
          <strong class="strongalert">Notificación de Advertencia</strong>
          <p class="parrafoalert">Está a punto de reactivar un cliente. Una vez activado se podrán generar servicios y asignaciones de elementos.</p>
          <p class="questionoalert">¿Está seguro de que desea continuar?</p>
        `,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Request(
            `/client/reactivate/${row.idClient}`,
            { isActive: !row.isActive },
            "PATCH"
          );
          if (res.ok) {
            Swal.fire(
              "Éxito",
              `Cliente ${row.isActive ? "desactivado" : "activado"} con éxito.`,
              "success"
            );
          } else {
            Swal.fire("Error", res.error, "error");
          }
          actions.fetchData();
        }
      });
    },
    desActivateServiceTemplate: async (row) => {

      Swal.fire({
        html: `
        <hr style="border: 1px solid #ccc; margin:0; margin-bottom: 5px;">
          <strong class="strongalert">Notificación de Advertencia</strong>
          <p class="parrafoalert">Está a punto de desactivar un servicio. Una vez desactivado, no se podrán asignar elementos.</p>
          <p class="questionoalert">¿Está seguro de que desea continuar?</p>
        `,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setSingleData(row);
          setModalOpenCancelTemplate(true);
        }
      });
    },
    activateServiceTemplate: async (row) => {
      Swal.fire({
        // title: "¿Confirma la siguiente operación?",
        // html: `Se dará de ${row.isActive ? "baja" : "alta"} al cliente ${
        //   row.nameClient
        // }`,
        html: `
        <hr style="border: 1px solid #ccc; margin:0; margin-bottom: 5px;">
          <strong class="strongalert">Notificación de Advertencia</strong>
          <p class="parrafoalert">Está a punto de reactivar un servicio. Al continuar, todas las asignaciones de elementos se habilitarán, y será necesario verificar y actualizar la información del servicio.</p>
          <p class="questionoalert">¿Está seguro de que desea continuar?</p>
        `,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {

            setLoading(true);
            const res = await Request(
              `/service/reactivetemplateService/${row.idServiceTemplate}`,
              { isActive: !row.isActive },
              "PATCH"
            );
            console.log(res)
            setLoading(false);
            if (res.ok) {
              Swal.fire("Éxito", "El servicio se reactivo con éxito.", "success").then(
                () => {
  
                }
              );
            } else {

              Swal.fire("Error", res.msg, "error");
            }
            actions.fetchData();
          

        }
      });
    },
  };
  return (
    <Skinpage pageTitle={"Seguimiento de Clientes"}>
      <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            actions.handleSearch(e.target.value);
          }}
          value={state.search}
          placeholder="Buscar cliente"
          iconName="Search"
        />
        <DgInput
          type="select"
          label="Región"
          options={state.regions}
          onChange={actions.handleFilters}
          name="region"
          value={state.filters.region}
          iconName="Map"
        />
        <DgInput
          type="select"
          label="Tipo de cliente"
          options={[
            {
              label: "Todos",
              value: "",
            },
            ...clientTypes,
          ]}
          onChange={actions.handleFilters}
          name="clientType"
          value={state.filters.clientType}
          iconName="Users"
        />
        <DgInput
          type="select"
          label="Tipo de vista"
          options={enumViewTypes}
          onChange={actions.handleFilters}
          name="viewType"
          value={state.filters.viewType}
          iconName="Filter"
        />
        <Button
          containerStyle={{ width: "fit-content" }}
          style={{ width: 100 }}
          tooltip={"Descargar reporte de plantillas de servicios"}
          onClick={actions.downloadServicesTemplatesReport}
        >
          <TbFileDownload size={35} />
        </Button>
        <Button
          style={{ width: 100 }}
          tooltip={"Descargar reporte de clientes"}
          onClick={actions.downloadClientsReport}
        >
          <BsPersonDown size={35} />
        </Button>
      </div>
      <br />
      <div className="w-full overflow-auto">
        <DgTable
          className={"min-w-[1500px]"}
          data={state.data}
          columnsDef={getColumnsClients(funcs)}
        />
      </div>
      <ModalPlantillaServicesFromClientTable
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
        singleData={singleData}
        refresh={() => {
          refreshTableClient();
        }}
      />
      <ModalCancelarPlantillaServicesFromClientTable
        isOpen={modalOpenCancelTemplate}
        toggle={() => {
          setModalOpenCancelTemplate(!modalOpenCancelTemplate);
        }}
        singleData={{idServiceTemplate:singleData.idServiceTemplate}}
        refresh={() => {
          refreshTableClient();
        }}
        
      />
      <DgLoader open={isLoading} />
    </Skinpage>
  );
};

export default ListClient;
