import React, { useState } from "react";
import DgInput from "../../../components/DgInput";
import { Wallet, Edit, PlusSquare, View, Eye, Circle, CircleSlashed, Slash, Check } from "lucide-react";
import ModalPlantillaServices from "../ModalPlantillaServices";
import Title from "../../../components/Title";
import { Tooltip } from "@mui/material";
import ModalCancelarPlantillaServices from "../ModalCancelarPlantillaServices";
import Swal from "sweetalert2";
import { Request } from "../../../helpers/api";
import DgLoader from "../../../components/DgLoader";

const ListServices = (props) => {
  const { clickNewService, clientServices } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const [services, setServices] = useState(clientServices);
  const [servicesAll] = useState(clientServices);
  const [valSearch, setValSearch] = useState("");
  const [serviceAct, setServiceAct] = useState({});
  const [modalOpenCancelTemplate, setModalOpenCancelTemplate] = useState(false);

  const inactiveServicePrev = () => {

    Swal.fire({
      html: `
      <hr style="border: 1px solid #ccc; margin:0; margin-bottom: 5px;">
        <strong class="strongalert">Notificación de Advertencia</strong>
        <p class="parrafoalert">Está a punto de desactivar un servicio. Una vez desactivado, no se podrán asignar elementos.</p>
        <p class="questionoalert">¿Está seguro de que desea continuar?</p>
      `,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      // reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setModalOpenCancelTemplate(true);
      }
    });

  };
  const handleCloseInactiveTemplate = () => {
    setModalOpenCancelTemplate(false);
  };

  const handleReactiveService = (serv) => {
console.log({serv})
     Swal.fire({
        // title: "¿Confirma la siguiente operación?",
        // html: `Se dará de ${row.isActive ? "baja" : "alta"} al cliente ${
        //   row.nameClient
        // }`,
        html: `
        <hr style="border: 1px solid #ccc; margin:0; margin-bottom: 5px;">
          <strong class="strongalert">Notificación de Advertencia</strong>
          <p class="parrafoalert">Está a punto de reactivar un servicio. Al continuar, todas las asignaciones de elementos se habilitarán, y será necesario verificar y actualizar la información del servicio.</p>
          <p class="questionoalert">¿Está seguro de que desea continuar?</p>
        `,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
console.log("serviceAct.idServiceTemplate",serv.idServiceTemplate)
            setLoading(true);
            const res = await Request(
              `/service/reactivetemplateService/${serv.idServiceTemplate}`,
              { isActive: !serv.isActive },
              "PATCH"
            );
            setLoading(false);
            console.log(res)
            if (res.ok) {
              Swal.fire("Éxito", "El servicio se reactivo con éxito.", "success").then(
                () => {
                  console.log(res.client.serviceTemplates)
                  //if(updateServicesList){
                    setServices(res.client?.serviceTemplates || []);
  
                }
              );
            } else {

              Swal.fire("Error", res.msg, "error");
            }

          

        }
      });
  };

  const handleSearch = (e) => {
    const valS = e.target.value;
    setValSearch(valS);
    if (valS != "") {
      let serviceAux = servicesAll.filter((serv) => {
        return serv.name?.toLowerCase().includes(valS.toLowerCase());
      });
      setServices(serviceAux);
    } else {
      setServices(servicesAll);
    }
  };

  return (
    <>
      <div style={{ width: "50%" }}>
        <Title className={"py-3"}>Lista de plantillas de servicios</Title>
        <DgInput
          placeholder="Buscar Servicio"
          iconName="Search"
          containerClassName="mt-0"
          onChange={handleSearch}
          value={valSearch}
        />

        {services.map((serv) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // backgroundColor: "lavender",
                backgroundColor: ` ${serv.isActive === true ? 'lavender' : '#FFCCCC'}`,
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "0.5rem",
              }}
            >
              <div
                style={{
                  height: "7.5rem",
                  width: "1rem",
                  backgroundColor: ` ${serv.isActive === true ? '#2A2F99' : '#B90E0A'}`,
                  opacity: 0.5,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1rem",
                  padding: "0.6rem 0rem",
                  width: "25rem",
                }}
              >
                <div style={{ color: "#2A2F99" }}>{serv.name}</div>
                <div style={{ color: "grey" }}>{serv.paymentPeriod}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0rem 1rem",
                }}
              >
                {
                  serv.isActive ?
                  (
                    <>
                      <Tooltip title="Editar servicio">
                        <Edit
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOpen();
                            setServiceAct(serv);
                          }}
                        />                          
                      </Tooltip>  
 
                      <Tooltip title="Dar de baja el servicio">
                        <Slash
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          inactiveServicePrev();
                          setServiceAct(serv);
                        }}
                        />  
                      </Tooltip>                       
                    </>
              
                  )

                  :
                  (
                    <>
                     <Tooltip title="Ver detalle de servicio">
                        <Eye
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleOpen();
                          setServiceAct(serv);
                        }}
                        />                          
                     </Tooltip>  
                     <Tooltip title="Reactivar servicio">
                        <Check
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleReactiveService(serv);
                          setServiceAct(serv);
                        }}
                        />  
                      </Tooltip> 
                    </>

                  )
                }

                &nbsp;
                {/*<Wallet/>*/}
              </div>
            </div>
          );
        })}
        <div
          onClick={clickNewService}
          style={{
            color: "#2A2F99",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <PlusSquare fill="#2A2F99" color="white" size={35} />
          Añadir otro servicio
        </div>
      </div>
      <ModalPlantillaServices
        toggle={() => {
          setOpen(!open);
        }}
        isOpen={open}
        onClose={handleClose}
        singleData={serviceAct}
        updateServicesList={(newServices) => {
          setServices(newServices);
        }}
      />
      <ModalCancelarPlantillaServices
        toggle={() => {
          setModalOpenCancelTemplate(!modalOpenCancelTemplate);
        }}
        isOpen={modalOpenCancelTemplate}
        onClose={handleCloseInactiveTemplate}
        singleData={{idServiceTemplate:serviceAct.idServiceTemplate}}
        updateServicesList={(newServices) => {
          setServices(newServices);
        }}
      />
            <DgLoader open={loading} />
    </>
  );
};

export default ListServices;
