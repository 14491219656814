import React, { useEffect, useState } from "react";
import { Request } from "../../helpers/api";
import { parseCat, parseInvoiceErrors } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { enumPaymentMethods } from "../../helpers/enums";
import ClientCard from "../../components/ClientCard";
import DgInput from "../../components/DgInput";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import useGetScreenWidth from "../../helpers/useGetScreenWidth";
import PostInvoiceModal from "./modals/PostInvoiceModal";

const InvoiceClients = ({ services = [] }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [singleData, setSingleData] = useState([]);
  const [postInvoiceModal, setPostInvoiceModal] = useState(false);
  const [screenWidth, handleScreenWidth] = useGetScreenWidth();
  useEffect(() => {
    handleScreenWidth();
  }, []);

  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);
  const fetchData = useCallback(async (ix, ac) => {
    const newAc = [...ac];
    const s = services[ix];
    if (s) {
      let service = {};
      setIsLoading(true);
      const res = await Request("/service/" + s.idService);
      if (res.ok) {
        const { data } = res;
        const { serviceConcepts } = data;
        service = {
          ...data,
          serviceConcepts: serviceConcepts.map((sc) => ({
            ...sc,
            absenceDiscount: sc.absenceDiscount || 0,
            viewGuardServiceInInvoice: sc.viewGuardServiceInInvoice || false,
          })),
        };
        const resC = await Request("/client/" + s.idClient);
        setIsLoading(false);
        if (resC.ok) {
          service.client = resC.data;
          newAc.push(service);
          const newIx = ix + 1;
          if (newIx < services.length) {
            fetchData(newIx, newAc);
          } else {
            setData(newAc);
          }
        }
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  const onNestedChange = (sIx, ix) => (e) => {
    const { value, name } = e.target;
    const newData = [...data];
    const service = newData[sIx];
    const item = service.serviceConcepts[ix];
    item[name] = value;
    newData[sIx] = service;
    //console.log("newData",newData)
    setData(newData);
  };
  const onNestedChangeCheckbox = (sIx, ix) => (e) => {
    const { value, name } = e.target;
    const newData = [...data];
    const service = newData[sIx];
    const item = service.serviceConcepts[ix];
    item[name] = e.target.checked;
    newData[sIx] = service;
    //console.log("newData",newData)
    setData(newData);
  };
  const onChange = (ix) => (e) => {
    const newData = [...data];
    const service = newData[ix];
    service[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { objupdateafacturar, ok } = await Request(
      "/invoice",
      {
        invoices: data.map(({ idService, serviceConcepts, paymentMethod }) => ({
          idService,
          serviceConcepts,
          paymentMethod,
        })),
      },
      "POST"
    );
    setIsLoading(false);
    if (ok) {
      const errorsList = [];
      objupdateafacturar.forEach(
        ({ createdPdf, invoiced, remitted, msgInvoiced }, index) => {
          const completedInvoice = createdPdf && (invoiced || remitted);
          if (!completedInvoice) {
            errorsList.push({
              nameService: `Servicio ${index + 1}`,
              msgInvoiced,
            });
          }
        }
      );
      let completedServices = data.length - errorsList.length;
      const allInvoiced = errorsList.length === 0;
      Swal.fire({
        title: allInvoiced ? "Éxito" : "Error",
        html:
          `${completedServices} / ${data.length} Servicios Facturados <br><br>` +
          (!allInvoiced
            ? `${errorsList.map(
                (e) =>
                  "<b>" +
                  e.nameService +
                  "</b>" +
                  ": " +
                  parseInvoiceErrors(e.msgInvoiced) +
                  "<br><br>"
              )}`
            : ""),
        icon: allInvoiced ? "success" : "warning",
      }).then(() => {
        setCompleted(true);
        setSingleData(objupdateafacturar);
        setPostInvoiceModal(true);
        setData([]);
      });
    }
  };
  const createSelector = (c, ix) => {
    return (
      <DgInput
        type="select"
        name="paymentMethod"
        value={c.paymentMethod}
        disabled={c.clientTypeService === "FISCAL" ? false : true}
        onChange={onChange(ix)}
        options={
          c.clientTypeService === "FISCAL"
            ? [
                { value: "", label: "Selecciona un elemento" },
                ...enumPaymentMethods,
              ]
            : [{ value: "PUE", label: "PUE" }]
        }
        iconName="FormInput"
        containerClassName={"invoicingDgInput"}
      />
    );
  };
  useEffect(() => {
    fetchData(0, []);
  }, [fetchData]);
  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit}>
        {screenWidth >= 1100 ? (
          <div className="grid grid-cols-3">
            <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
              CLIENTES A FACTURAR
            </h2>
            <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
              CONCEPTOS
            </h2>
          </div>
        ) : (
          <h1
            className="text-sesBlue font-semibold text-lg"
            style={{ textAlign: "center" }}
          >
            CLIENTES A FACTURAR Y CONCEPTOS
          </h1>
        )}

        {data.map((s, sIx) => (
          <div
            style={{
              display: "flex",
              flexDirection: screenWidth <= 1100 ? "column" : "row",
              width: "100%",
              borderBottom: sIx !== data.length - 1 && "1px solid #2A2F99",
              padding: "20px 0px",
              gap: 20,
            }}
          >
            <ClientCard
              md="16"
              client={s.client}
              service={s}
              bottomComponent={createSelector(s, sIx)}
              style={{
                display: "flex",
                maxWidth: screenWidth <= 1100 ? "100%" : "30%",
                height: "fit-content",
              }}
            />
            <div style={{ width: "100%" }}>
              {s.serviceConcepts?.map((sc, ix) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 20,
                  }}
                >
                  <DgInput
                    style={{ width: "300px" }}
                    containerClassName={"invoicingConceptsInput"}
                    {...{
                      label: "Concepto de factura",
                      placeholder: "Concepto de factura",
                      type: "select",
                      options: [
                        { value: "", label: "Elige un concepto" },
                        ...parseCat(catConcepts, "name", "idCatServiceConcept"),
                      ],
                      name: "idCatServiceConcept",
                      required: true,

                      iconName: "Files",
                      value: sc.idCatServiceConcept,
                      onInputChange: onNestedChange(sIx, ix),
                      errorMessage: "Éste concepto de factura no es válido",
                    }}
                  />
                  <DgInput
                    containerClassName={"invoicingConceptsInput"}
                    style={{ width: "150px" }}
                    disabled={
                      s.idCatStatusPaymentService === 3 ||
                      s.idCatStatusPaymentService === 6
                        ? true
                        : false
                    }
                    {...{
                      label: "Cantidad",
                      placeholder: "2",
                      minLength: "1",
                      maxLength: "2",
                      min: "1",
                      errorMessage: "Valor requerido y no puede ser menor a 1",
                      type: "number",
                      name: "quantity",
                      required: true,
                      iconName: "Hash",
                      value: sc.quantity,
                      onInputChange: onNestedChange(sIx, ix),
                      errorMessage: "Indique una cantidad en el rango 1 a 99",
                    }}
                  />
                  <DgInput
                    containerClassName={"invoicingConceptsInput"}
                    style={{ flex: 1 }}
                    disabled={
                      s.idCatStatusPaymentService === 3 ||
                      s.idCatStatusPaymentService === 6
                        ? true
                        : false
                    }
                    {...{
                      label: "Precio base",
                      type: "number",
                      name: "price",
                      step: 0.01,
                      min: 0.01,
                      required: true,
                      value: sc.price,
                      onInputChange: onNestedChange(sIx, ix),
                      iconName: "DollarSign",
                      errorMessage:
                        "Valor requerido y no puede ser menor a 0.01",
                    }}
                  />
                  <DgInput
                    containerClassName={"invoicingConceptsInput"}
                    style={{ width: "150px" }}
                    {...{
                      label: "Descuento faltas",
                      type: "number",
                      disabled:
                        s.idCatStatusPaymentService === 3 ||
                        s.idCatStatusPaymentService === 6
                          ? true
                          : false,
                      name: "absenceDiscount",
                      step: 0.01,
                      min: 0,
                      max: parseFloat(sc.price * sc.quantity),
                      required: true,
                      onInputChange: onNestedChange(sIx, ix),
                      value: sc.absenceDiscount,
                      iconName: "UserMinus",
                      defaultValue: 0,
                      errorMessage:
                        "Descuento requerido y no puede ser mayor al total",
                    }}
                  />
                  <DgInput
                    containerClassName={"invoicingConceptsInput"}
                    style={{ width: "250px" }}
                    {...{
                      label: "Observaciones",
                      type: "text",
                      disabled: false,
                      name: "description",
                      required: false,
                      onInputChange: onNestedChange(sIx, ix),
                      value: sc.description,
                      iconName: "FormInput",
                    }}
                  />
                  <DgInput
                    containerClassName={"invoicingConceptsInput"}
                    style={{ width: "200px" }}
                    {...{
                      label: "Mostrar servicio de vigilancia en factura",
                      type: "checkbox",
                      disabled: false,
                      name: "viewGuardServiceInInvoice",
                      required: false,
                      onInputChange: onNestedChangeCheckbox(sIx, ix),
                      value: sc.viewGuardServiceInInvoice,
                      //iconName: "FormInput",
                    }}
                  />                  
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button disabled={isLoading || completed} type="submit">
            Facturar
          </Button>
        </div>
      </form>

      <Loader isLoading={isLoading} />

      <PostInvoiceModal
        data={singleData}
        open={postInvoiceModal}
        toggle={() => {
          setPostInvoiceModal((currentState) => !currentState);
        }}
      />
    </div>
  );
};

InvoiceClients.propTypes = {};

export default InvoiceClients;
